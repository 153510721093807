import React, { useState, useCallback, useRef, useEffect } from "react";
import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import Swal from "sweetalert2";
import { Form } from "@unform/web";

import {
  QuestionarioContainer,
  BackgroundQuestionario,
  Container,
  Finalizado,
  MapContainer,
} from "./styles";

import * as Yup from "yup";
import getValidationErrors from "../../Utils/getValidationErrors";
import Input from "../../Components/Input";
import InputMask from "../../Components/InputMask";
import Select from "../../Components/Select";
import { useParams, useHistory } from "react-router-dom";

import finalizadoImg from "../../Assets/finalizado.svg";
import dadosPessoais from "../../Assets/dadosPessoais.svg";
import doutorCheckUp from "../../Assets/doutorCheckUp.svg";
import doutor from "../../Assets/doutor.svg";
import isolamentoSocial from "../../Assets/isolamentoSocial.svg";
import lavandoMaos from "../../Assets/lavandoMaos.svg";
import destination from "../../Assets/destination.svg";

import api from "../../Services/api";

function Editar() {
  let { id } = useParams();
  const history = useHistory();
  const [dados, setDados] = useState({});

  const [iniciou, setIniciou] = useState(true);
  const [finalizado, setFinalizado] = useState(false);

  const [primeiraParte, setPrimeiraParte] = useState(true);
  const [segundaParte, setSegundaParte] = useState(false);
  const [terceiraParte, setTerceiraParte] = useState(false);
  const [quartaParte, setQuartaParte] = useState(false);
  const [quintaParte, setQuintaParte] = useState(false);
  const [sextaParte, setSextaParte] = useState(false);
  const [latitude, setLatitude] = useState(-10.1835604);
  const [longitude, setLongitude] = useState(-48.3337793);

  const formRef = useRef(null);
  const formRef2 = useRef(null);
  const formRef3 = useRef(null);
  const formRef4 = useRef(null);
  const formRef5 = useRef(null);
  const formRef6 = useRef(null);

  useEffect(() => {
    if (navigator.geolocation) {
      var options = {
        enableHighAccuracy: true,
        maximumAge: 100,
        timeout: 60000,
      };
      navigator.geolocation.watchPosition(
        function (position) {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
          if (formRef6.current) {
            formRef6.current.setData({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          }
        },
        function (erro) {},
        options
      );
    }
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get(`questionario/${id}`);

        setDados(data);
        if (formRef.current) formRef.current.setData(data);
      } catch (error) {
        console.log(error);
        alert("Error ao buscar os dados");
        history.push("/app");
      }
    }

    loadData();
  }, []);

  const handleMapDoubleClick = (e) => {
    setLatitude(e.latlng.lat);
    setLongitude(e.latlng.lng);
    if (formRef6.current) {
      formRef6.current.setData({
        latitude: e.latlng.lat,
        longitude: e.latlng.lng,
      });
    }
  };

  const handleSubmitParte1 = async (dadosPrimeiraParte) => {
    try {
      formRef.current.setErrors({});
      const schema = Yup.object().shape({
        nome: Yup.string().required('O campo "Nome" é obrigatório.'),
        sexo: Yup.string().required('O campo "Sexo" é obrigatório.'),
        endereco: Yup.string().required('O campo "Endereço" é obrigatório.'),
        territorioDeSaude: Yup.string().required(
          'O campo "Território de saúde" é obrigatório.'
        ),
        dataDeNascimento: Yup.string().required(
          'O campo "Data de nascimento" é obrigatório.'
        ),
        telefone: Yup.string().required('O campo "Telefone" é obrigatório.'),
        whatsapp: Yup.string().required('O campo "Whatsapp" é obrigatório.'),
        raca: Yup.string("O campo deve ser um número válido").required(
          'O campo "Raça" é obrigatório.'
        ),
        estadoCivil: Yup.string().required(
          'O campo "Estado civil" é obrigatório.'
        ),
        situacaoDaMoradia: Yup.string().required(
          'O campo "Qual é a situação da sua moradia" é obrigatório.'
        ),
        tipoDeMoradia: Yup.string().required(
          'O campo "Tipo de moradia" é obrigatório.'
        ),
        quantidadeDePessoasNoDomicilio: Yup.string().required(
          'O campo "Quantas pessoas moram no seu domicílio? (Incluindo você)" é obrigatório.'
        ),
        tempoDeResidencia: Yup.string().required(
          'O campo "Tempo de residência no local atual" é obrigatório.'
        ),

        escolaridade: Yup.string().required(
          'O campo "Escolaridade" é obrigatório.'
        ),
        unidadeReferencia: Yup.string().required("O campo  é obrigatório."),
        zonaDeTrabalho: Yup.string().required("O campo  é obrigatório."),
        vinculoEmpregaticioAtual: Yup.string().required(
          "O campo  é obrigatório."
        ),
        rendaFamiliar: Yup.string().required("O campo  é obrigatório."),
        viajou: Yup.string().required("O campo  é obrigatório."),
      });

      await schema.validate(dadosPrimeiraParte, { abortEarly: false });

      Object.keys(dadosPrimeiraParte).forEach(
        (k) =>
          (dadosPrimeiraParte[k] =
            dadosPrimeiraParte[k] === "" ? null : dadosPrimeiraParte[k])
      );

      setDados(Object.assign(dados, dadosPrimeiraParte));
      //finalizar primeira parte
      setPrimeiraParte(false);
      setSegundaParte(true);
      window.scrollTo(0, 0);
      await formRef2.current.setData(dados);
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef.current.setErrors(errors);
    }
  };

  const handleSubmitParte2 = async (dadosSegundaParte) => {
    try {
      formRef2.current.setErrors({});
      const schema = Yup.object().shape({
        estadoDeSaudeNoUltimoMes: Yup.string().required(
          'O campo "O que você acha do seu estado de saúde no último mês?" é obrigatório.'
        ),
        fuma: Yup.string().required("O campo é obrigatório."),
        outrosProblemasDeSaude: Yup.string().required("O campo é obrigatório."),
        consomeBebida: Yup.string().required(
          'O campo "Você consome bebida alcoólica?'
        ),
        portadorDasDoencas: Yup.string().required(
          'O campo "É portador de algum desses agravos/ doenças?" é obrigatório.'
        ),
      });

      await schema.validate(dadosSegundaParte, { abortEarly: false });
      Object.keys(dadosSegundaParte).forEach(
        (k) =>
          (dadosSegundaParte[k] =
            dadosSegundaParte[k] === "" ? null : dadosSegundaParte[k])
      );
      setDados(Object.assign(dados, dadosSegundaParte));
      //finalizar segunda parte
      setSegundaParte(false);
      setTerceiraParte(true);
      window.scrollTo(0, 0);
      await formRef3.current.setData(dados);
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef2.current.setErrors(errors);
    }
  };

  const handleSubmitParte3 = async (dadosTerceiraParte) => {
    try {
      formRef3.current.setErrors({});
      const schema = Yup.object().shape({
        frequenciaDaHigienizacaoDasMaos: Yup.string().required(
          'O campo "Com que frequência higieniza suas mãos?" é obrigatório.'
        ),
        fazUsoDeProdutosDeHigienizacao: Yup.string().required(
          'O campo "Faz uso de água e sabão ou desinfetante à base de álcool para higienização?" é obrigatório.'
        ),
        ondeDescartaMascara: Yup.string().required(
          'O campo "Onde você descarta a máscara?" é obrigatório.'
        ),
        higienizaAsMaos: Yup.string().required("O campo é obrigatório."),
        higienizaObjetos: Yup.string().required("O campo é obrigatório."),
        higienizaMascaraAposUso: Yup.string().required(
          "O campo é obrigatório."
        ),
        fazUsoDeMascara: Yup.string().required("O campo é obrigatório."),
        contatoInfectado: Yup.string().required("O campo é obrigatório."),
        evitadoTocarOlhosNarizBoca: Yup.string().required(
          "O campo é obrigatório."
        ),
        temAdotadoPraticasRecomendadas: Yup.string().required(
          "O campo é obrigatório."
        ),
      });

      await schema.validate(dadosTerceiraParte, { abortEarly: false });
      Object.keys(dadosTerceiraParte).forEach(
        (k) =>
          (dadosTerceiraParte[k] =
            dadosTerceiraParte[k] === "" ? null : dadosTerceiraParte[k])
      );
      setDados(Object.assign(dados, dadosTerceiraParte));
      // //finalizar terceira parte
      setTerceiraParte(false);
      setQuartaParte(true);
      window.scrollTo(0, 0);
      await formRef4.current.setData(dados);
    } catch (err) {
      const errors = getValidationErrors(err);
      formRef3.current.setErrors(errors);
    }
  };

  const handleSubmitParte4 = useCallback(
    async (dadosQuartaParte) => {
      try {
        formRef4.current.setErrors({});
        const schema = Yup.object().shape({
          frequenciaQueSaiuDeCasa: Yup.string().required(
            'O campo "Com que frequência você sai diariamente?" é obrigatório.'
          ),
          frequenciaQueSaiuNaUltimaSemana: Yup.string().required(
            "O campo é obrigatório."
          ),
          realizaIsolamentoSocial: Yup.string().required(
            "O campo é obrigatório."
          ),
        });

        await schema.validate(dadosQuartaParte, { abortEarly: false });
        Object.keys(dadosQuartaParte).forEach(
          (k) =>
            (dadosQuartaParte[k] =
              dadosQuartaParte[k] === "" ? null : dadosQuartaParte[k])
        );
        setDados(Object.assign(dados, dadosQuartaParte));
        // //finalizar quarta parte
        setQuartaParte(false);
        setQuintaParte(true);
        window.scrollTo(0, 0);
        await formRef5.current.setData(dados);
      } catch (err) {
        const errors = getValidationErrors(err);
        formRef4.current.setErrors(errors);
        console.log(errors);
      }
    },
    [dados]
  );

  const handleSubmitParte5 = useCallback(
    async (dadosQuintaParte) => {
      try {
        formRef5.current.setErrors({});
        const schema = Yup.object().shape({
          trocaDeRoupaAoChegarEmCasa: Yup.string().required(
            "O campo é obrigatório."
          ),
          deixaSapatosEmLocalApropriado: Yup.string().required(
            "O campo é obrigatório."
          ),
          higienizaSapatosAntesDeEntrarEmCasa: Yup.string().required(
            "O campo é obrigatório."
          ),
          compartilhaObjetos: Yup.string().required("O campo é obrigatório."),
        });

        await schema.validate(dadosQuintaParte, { abortEarly: false });
        Object.keys(dadosQuintaParte).forEach(
          (k) =>
            (dadosQuintaParte[k] =
              dadosQuintaParte[k] === "" ? null : dadosQuintaParte[k])
        );
        setDados(Object.assign(dados, dadosQuintaParte));
        setQuintaParte(false);
        setSextaParte(true);
        window.scrollTo(0, 0);
        await formRef6.current.setData(dados);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef5.current.setErrors(errors);
        }
      }
    },
    [dados]
  );

  const handleSubmitParte6 = useCallback(
    async (dadosSextaParte) => {
      try {
        formRef6.current.setErrors({});
        const schema = Yup.object().shape({
          latitude: Yup.string().required("Selecione um local no mapa"),
          longitude: Yup.string().required("Selecione um local no mapa"),
          data: Yup.string().required("Informe a data da coleta"),
          horario: Yup.string().required("Informe o horário da coleta"),
          nomeResidente: Yup.string().required("Informe o horário da coleta"),
          diaExame: Yup.string().required("Informe o horário da coleta"),
          horarioExame: Yup.string().required("Informe o horário da coleta"),
          chave: Yup.string()
            .required("Informe sua chave de acesso")
            .min(4, "O mínimo da chave 4")
            .max(6, "O máximo da chave é 6"),
        });

        await schema.validate(dadosSextaParte, { abortEarly: false });
        Object.keys(dadosSextaParte).forEach(
          (k) =>
            (dadosSextaParte[k] =
              dadosSextaParte[k] === "" ? null : dadosSextaParte[k])
        );
        setDados(Object.assign(dados, dadosSextaParte));
        await api.put(
          `questionario/${id}`,
          Object.assign(dados, dadosSextaParte)
        ); // enviar dados para o serv
        setSextaParte(false);
        setDados({});
        window.scrollTo(0, 0);
        alert("Dados Atualizados com Sucesso");
        history.push("/app");
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef6.current.setErrors(errors);
        }
        if (err.response) {
          alert("Erro ao processar o formulário. Favor tentar novamente.");
        }
      }
    },
    [dados]
  );

  return (
    <>
      <BackgroundQuestionario>
        <section>
          {primeiraParte && (
            <Container>
              <img src={dadosPessoais} alt="Dados Pessoais" />
              <h3>Dados socioeconômicos e demográficos</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef}
                onSubmit={handleSubmitParte1}
                initialData={dados}
              >
                <Input name="nome" span="Nome:*" />
                <Select
                  name="sexo"
                  span="Sexo: *"
                  options={[
                    { value: "Feminino", label: "Feminino" },
                    { value: "Masculino", label: "Masculino" },
                  ]}
                />
                <Input name="endereco" span="Endereço: *" />
                <Select
                  name="territorioDeSaude"
                  span="Território de Saúde: *"
                  options={[
                    { value: "Apinajé", label: "Apinajé" },
                    { value: "Javaé", label: "Javaé" },
                    { value: "Kanela", label: "Kanela" },
                    { value: "Karajá", label: "Karajá" },
                    { value: "Krahô", label: "Krahô" },
                    { value: "Pankararu", label: "Pankararu" },
                    { value: "Xambioá", label: "Xambioá" },
                    { value: "Xerente", label: "Xerente" },
                  ]}
                />

                <InputMask
                  type="text"
                  mask="99/99/9999"
                  name="dataDeNascimento"
                  span="Data de nascimento:*"
                />
                <Input
                  name="unidadeReferencia"
                  span="Unidade de Referência:*"
                />
                <InputMask
                  mask="(99) 99999-9999"
                  name="telefone"
                  span="Telefone: *"
                />
                <Select
                  name="whatsapp"
                  span="WhatsApp*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="raca"
                  span="Cor/Raça*"
                  options={[
                    { value: "Branco", label: "Branco" },
                    { value: "Preta", label: "Preta" },
                    { value: "Pardo", label: "Pardo" },
                    { value: "Amarelo", label: "Amarelo" },
                    { value: "Indígena", label: "Indígena" },
                  ]}
                />
                <Select
                  name="estadoCivil"
                  span="Estado civil:*"
                  options={[
                    { value: "Solteiro (a)", label: "Solteiro (a)" },
                    {
                      value: "Casado (a) / União Estável",
                      label: "Casado (a) / União Estável",
                    },
                    { value: "Divorciado (a)", label: "Divorciado (a)" },
                    { value: "Viúvo (a) ", label: "Viúvo (a) " },
                  ]}
                />
                <Select
                  name="situacaoDaMoradia"
                  span="Qual é a situação da sua moradia:*"
                  options={[
                    { value: "Própria", label: "Própria" },
                    { value: "Alugada", label: "Alugada" },
                    { value: "Cedida", label: "Cedida" },
                    { value: "Outros", label: "Outros" },
                  ]}
                />
                <Select
                  name="tipoDeMoradia"
                  span="Tipo de moradia:*"
                  options={[
                    { value: "Casa", label: "Casa" },
                    { value: "Apartamento", label: "Apartamento" },
                    { value: "Kitnet", label: "Kitnet" },
                  ]}
                />
                <Input
                  name="quantidadeDePessoasNoDomicilio"
                  span="Quantas pessoas moram no seu domicílio? (Incluindo você):*"
                />
                <Select
                  name="tempoDeResidencia"
                  span="Tempo de residência no local atual:*"
                  options={[
                    { value: "Menos de 1 ano", label: "Menos de 1 ano" },
                    { value: "01 a 02 anos", label: "01 a 02 anos" },
                    { value: "02 a 05 anos", label: "02 a 05 anos" },
                    { value: "05 a 10 anos", label: "05 a 10 anos" },
                    { value: "Mais de 10 anos", label: "Mais de 10 anos" },
                  ]}
                />
                <Select
                  name="zonaDeTrabalho"
                  span="Em que zona trabalha*"
                  options={[
                    { value: "Não trabalha", label: "Não trabalha" },
                    { value: "Urbana", label: "Urbana" },
                    { value: "Rural", label: "Rural" },
                  ]}
                />
                <Select
                  name="vinculoEmpregaticioAtual"
                  span="Qual é o seu vínculo empregatício atual:*"
                  options={[
                    { value: "Desempregado", label: "Desempregado" },
                    {
                      value: "Estatutário (servidor público)",
                      label: "Estatutário (servidor público)",
                    },
                    { value: "Celetista", label: "Celetista" },
                    { value: "Contrato", label: "Contrato" },
                    { value: "Comissionado", label: "Comissionado" },
                    { value: "Autônomo", label: "Autônomo" },
                    { value: "Outro", label: "Outro" },
                  ]}
                />
                <Input
                  name="vinculoEmpregaticioAtualOutro"
                  span="Se marcou outro, descreva:"
                />
                <Select
                  name="rendaFamiliar"
                  span="Renda familiar:*"
                  options={[
                    {
                      value: "Não possui renda",
                      label: "Não possui renda",
                    },
                    {
                      value: "Abaixo de 1 salário mínimo",
                      label: "Abaixo de 1 salário mínimo",
                    },
                    { value: "1 salário mínimo", label: "1 salário mínimo" },
                    {
                      value: "Até 2 salários mínimos",
                      label: "Até 2 salários mínimos",
                    },
                    {
                      value: "Até 3 salários mínimos",
                      label: "Até 3 salários mínimos",
                    },
                    {
                      value: "4 ou mais salários mínimos",
                      label: "4 ou mais salários mínimos",
                    },
                  ]}
                />
                <Select
                  name="escolaridade"
                  span="Escolaridade:*"
                  options={[
                    {
                      value: "Analfabeto",
                      label: "Analfabeto",
                    },
                    {
                      value: "Ensino fundamental incompleto",
                      label: "Ensino fundamental incompleto",
                    },
                    {
                      value: "Ensino fundamental completo",
                      label: "Ensino fundamental completo",
                    },
                    {
                      value: "Ensino médio incompleto",
                      label: "Ensino médio incompleto",
                    },
                    {
                      value: "Ensino médio completo",
                      label: "Ensino médio completo",
                    },
                    {
                      value: "Superior incompleto",
                      label: "Superior incompleto",
                    },
                    {
                      value: "Superior completo",
                      label: "Superior completo",
                    },
                    { value: "Pós-graduação", label: "Pós-graduação" },
                  ]}
                />
                <Select
                  name="viajou"
                  span="Viajou nas últimas 4 semanas?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Input name="viajouOutro" span="Se sim, local(is):" />
                <button type="submit">Continuar</button>
              </Form>
            </Container>
          )}
          {segundaParte && (
            <Container>
              <img src={doutorCheckUp} alt="Condições atuais de saúde" />
              <h3>Condições atuais de saúde</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef2}
                onSubmit={handleSubmitParte2}
                initialData={dados}
              >
                <Select
                  name="estadoDeSaudeNoUltimoMes"
                  span="O que você acha do seu estado de saúde no último mês? *"
                  options={[
                    { value: "Muito bom", label: "Muito bom" },
                    { value: "Bom", label: "Bom" },
                    { value: "Regular", label: "Regular" },
                    { value: "Ruim", label: "Ruim" },
                    { value: "Muito Ruim", label: "Muito Ruim" },
                  ]}
                />
                <Select
                  name="fuma"
                  span="Você fuma?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="quantidadeDeCigarrosConsumidos"
                  span="Se fuma, qual a quantidade de cigarros consumidos?"
                  options={[
                    {
                      value: "Menos de 10 cigarros/ dia",
                      label: "Menos de 10 cigarros/ dia",
                    },
                    {
                      value: "Entre 10 cigarros e 1 maço",
                      label: "Entre 10 cigarros e 1 maço",
                    },
                    { value: "1-3 maços/dia", label: "1-3 maços/dia" },
                    {
                      value: "Mais de 3 maços/dia",
                      label: "Mais de 3 maços/dia",
                    },
                  ]}
                />
                <Select
                  name="haQuantoTempoFuma"
                  span="Há quanto tempo fuma?"
                  options={[
                    { value: "Menos de 1 ano", label: "Menos de 1 ano" },
                    { value: "Entre 1 e 5 anos", label: "Entre 1 e 5 anos" },
                    {
                      value: "Entre 5 e 10 anos",
                      label: "Entre 5 e 10 anos",
                    },
                    { value: "10 anos ou mais", label: "10 anos ou mais" },
                  ]}
                />
                <Select
                  name="consomeBebida"
                  span="Você consome bebida alcoólica? *"
                  options={[
                    { value: "Não", label: "Não" },
                    { value: "Todos os dias", label: "Todos os dias" },
                    {
                      value: "1-2 vezes na semana",
                      label: "1-2 vezes na semana",
                    },
                    {
                      value: "3-4 vezes na semana",
                      label: "3-4 vezes na semana",
                    },
                    {
                      value: "5-6 vezes na semana",
                      label: "5-6 vezes na semana",
                    },
                  ]}
                />
                <Select
                  isMulti
                  name="portadorDasDoencas"
                  span="É portador de algum desses agravos/doenças? *"
                  options={[
                    { value: "Diabetes", label: "Diabetes" },
                    { value: "Hipertensão", label: "Hipertensão" },
                    {
                      value: "Insuficiência Renal Crônica",
                      label: "Insuficiência Renal Crônica",
                    },
                    {
                      value: "Doença Respiratória",
                      label: "Doença Respiratória",
                    },
                    {
                      value: "Doença Doença Cardiovascular",
                      label: "Doença Doença Cardiovascular",
                    },
                    { value: "Não", label: "Não" },
                  ]}
                />
                <Select
                  name="outrosProblemasDeSaude"
                  span="Você tem algum outro problema de saúde?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Input
                  name="outrosProblemasDeSaudeOutro"
                  span="Se sim, quais:"
                />
                <Select
                  isMulti
                  name="quizenDiasSintomas"
                  span="Nos 15 dias anteriores à entrevista, você apresentou algum destes sintomas"
                  options={[
                    { value: "Tosse", label: "Tosse" },
                    { value: "Febre", label: "Febre" },
                    {
                      value: "Cansaço",
                      label: "Cansaço",
                    },
                    {
                      value: "Dores no corpo",
                      label: "Dores no corpo",
                    },
                    {
                      value: "Dificuldade para respirar",
                      label: "Dificuldade para respirar",
                    },
                    {
                      value: "Alterações no paldar e olfato",
                      label: "Alterações no paldar e olfato",
                    },
                    {
                      value: "Diarreia e vômito",
                      label: "Diarreia e vômito",
                    },
                  ]}
                />
                <button type="submit">Continuar</button>
                <button
                  onClick={() => {
                    setSegundaParte(false);
                    setPrimeiraParte(true);
                    window.scrollTo(0, 0);
                  }}
                  type="button"
                  style={{ background: "grey" }}
                >
                  Voltar
                </button>
              </Form>
            </Container>
          )}
          {terceiraParte && (
            <Container>
              <img src={doutor} alt="Prevenção do Covid-19" />
              <h3>Prevenção do Covid-19</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef3}
                onSubmit={handleSubmitParte3}
                initialData={dados}
              >
                <Select
                  name="higienizaAsMaos"
                  span="Higieniza regularmente as mãos?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="frequenciaDaHigienizacaoDasMaos"
                  span="Com que frequência higieniza suas mãos?*"
                  options={[
                    { value: "1 vez/dia", label: "1 vez/dia" },
                    { value: "2 vezes/dia", label: "2 vezes/dia" },
                    {
                      value: "3 ou mais vezes/dia",
                      label: "3 ou mais vezes/dia",
                    },
                    { value: "Não lembra", label: "Não lembra" },
                  ]}
                />
                <Select
                  name="higienizaObjetos"
                  span="Higieniza regularmente os objetos de uso frequente?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="evitadoTocarOlhosNarizBoca"
                  span="Tem evitado tocar olhos, nariz e boca após contato com superfícies e pessoas?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="temAdotadoPraticasRecomendadas"
                  span="Tem adotado práticas recomendadas de etiqueta respiratória?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="fazUsoDeProdutosDeHigienizacao"
                  span="Faz uso de água e sabão ou desinfetante à base de álcool para higienização? *"
                  options={[
                    { value: "Água e sabão", label: "Água e sabão" },
                    { value: "Álcool 70%", label: "Álcool 70%" },
                    {
                      value: "Água e sabão e álcool 70%",
                      label: "Água e sabão e álcool 70%",
                    },
                  ]}
                />
                <Select
                  name="fazUsoDeMascara"
                  span="Faz uso de máscara?*"
                  options={[
                    {
                      value: "Cirúrgica/descartável",
                      label: "Cirúrgica/descartável",
                    },
                    { value: "PFF2", label: "PFF2" },
                    { value: "N95", label: "N95" },
                    { value: "Tecido", label: "Tecido" },
                  ]}
                />
                <Select
                  name="higienizaMascaraAposUso"
                  span="Higieniza a máscara após utilizá-la?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="ondeDescartaMascara"
                  span="Onde você descarta a máscara? *"
                  options={[
                    { value: "Lixo comum", label: "Lixo comum" },
                    {
                      value:
                        "Coloca em um saco plástico antes de descartar no lixo",
                      label:
                        "Coloca em um saco plástico antes de descartar no lixo",
                    },
                    { value: "Outros", label: "Outros" },
                  ]}
                />
                <Input
                  name="ondeDescartaMascaraOutro"
                  span="Se marcou outros, descreva:"
                />
                <Select
                  name="contatoInfectado"
                  span="Você teve contato com alguém que teve resultado para COVID-19 confirmado?*"
                  options={[
                    { value: "sim", label: "Sim" },
                    { value: "não", label: "Não" },
                    { value: "não sei", label: "Não Sei" },
                  ]}
                />
                <button type="submit">Continuar</button>
                <button
                  onClick={() => {
                    setTerceiraParte(false);
                    setSegundaParte(true);
                    window.scrollTo(0, 0);
                  }}
                  type="button"
                  style={{ background: "grey" }}
                >
                  Voltar
                </button>
              </Form>
            </Container>
          )}
          {quartaParte && (
            <Container>
              <img src={isolamentoSocial} alt="Isolamento Social" />
              <h3>Isolamento Social</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef4}
                onSubmit={handleSubmitParte4}
                initialData={dados}
              >
                <Select
                  name="realizaIsolamentoSocial"
                  span="Tem realizado ou está em isolamento social?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="frequenciaQueSaiuNaUltimaSemana"
                  span="Com que frequência saiu de casa durante a última semana?*"
                  options={[
                    { value: "Não saiu", label: "Não saiu" },
                    { value: "1 vez", label: "1 vez" },
                    { value: "2 vezes", label: "2 vezes" },
                    { value: "3 vezes", label: "3 vezes" },
                    { value: "4 vezes", label: "4 vezes" },
                    { value: "5 vezes", label: "5 vezes" },
                    { value: "6 vezes", label: "6 vezes" },
                    { value: "7 vezes", label: "7 vezes" },
                  ]}
                />
                <Input
                  name="quaisLocaisFrequenta"
                  span="Se sai de casa, quais locais frequenta?"
                />
                <Select
                  name="frequenciaQueSaiuDeCasa"
                  span="Com que frequência você sai diariamente?*"
                  options={[
                    { value: "Não Saio", label: "Não Saio" },
                    { value: "1 vez", label: "1 vez" },
                    { value: "2 vezes", label: "2 vezes" },
                    { value: "3 vezes ou mais", label: "3 vezes ou mais" },
                  ]}
                />
                <Select
                  isMulti
                  name="transporteParaLocomocao"
                  span="Qual transporte utiliza para locomoção?"
                  options={[
                    { value: "Público", label: "Público" },
                    { value: "Carro próprio", label: "Carro próprio" },
                    { value: "Motocicleta", label: "Motocicleta" },
                    { value: "Bicicleta", label: "Bicicleta" },
                    { value: "Uber", label: "Uber" },
                    { value: "A pé", label: "A pé" },
                    { value: "Outros", label: "Outros" },
                  ]}
                />
                <button type="submit">Continuar</button>
                <button
                  onClick={() => {
                    setQuartaParte(false);
                    setTerceiraParte(true);
                    window.scrollTo(0, 0);
                  }}
                  type="button"
                  style={{ background: "grey" }}
                >
                  Voltar
                </button>
              </Form>
            </Container>
          )}
          {quintaParte && (
            <Container>
              <img src={lavandoMaos} alt="Práticas Complementares de Higiene" />
              <h3>Práticas Complementares de Higiene</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef5}
                onSubmit={handleSubmitParte5}
                initialData={dados}
              >
                <Select
                  name="trocaDeRoupaAoChegarEmCasa"
                  span="Troca de roupa ao chegar em casa?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="deixaSapatosEmLocalApropriado"
                  span="Deixa os sapatos em local apropriado?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="higienizaSapatosAntesDeEntrarEmCasa"
                  span="Higieniza os sapatos antes de entrar em casa?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <Select
                  name="compartilhaObjetos"
                  span="Compartilha objetos de uso pessoal?*"
                  options={[
                    { value: true, label: "Sim" },
                    { value: false, label: "Não" },
                  ]}
                />
                <button type="submit">Continuar</button>
                <button
                  onClick={() => {
                    setQuintaParte(false);
                    setQuartaParte(true);
                    window.scrollTo(0, 0);
                  }}
                  type="button"
                  style={{ background: "grey" }}
                >
                  Voltar
                </button>
              </Form>
            </Container>
          )}
          {sextaParte && (
            <Container>
              <img src={destination} alt="Localização do Entrevistado" />
              <h3>Localização do Entrevistado</h3>

              <Form
                style={{ width: "100%" }}
                ref={formRef6}
                onSubmit={handleSubmitParte6}
                initialData={{ latitude, longitude }}
              >
                <Input
                  name="nomeResidente"
                  span="Nome do residente ou assistente de pesquisa*"
                />
                <Input name="chave" span="Senha*" />
                <InputMask
                  type="text"
                  mask="99/99/9999"
                  name="data"
                  span="Data Coleta: *"
                />
                <InputMask
                  type="text"
                  mask="99:99"
                  name="horario"
                  span="Horário Coleta: *"
                />
                <Select
                  name="diaExame"
                  span="Dia Exame?*"
                  options={[
                    { value: "Segunda", label: "Segunda" },
                    { value: "Terça", label: "Terça" },
                    { value: "Quarta", label: "Quarta" },
                    { value: "Quinta", label: "Quinta" },
                    { value: "Sexta", label: "Sexta" },
                    { value: "Sábado", label: "Sábado" },
                    { value: "Domingo", label: "Domingo" },
                  ]}
                />
                <Select
                  name="horarioExame"
                  span="Bloco Horário Exame?*"
                  options={[
                    { value: "I", label: "08 às 10h" },
                    { value: "II", label: "10 às 12h" },
                    { value: "III", label: "13h30 às 14h30" },
                    { value: "IV", label: "14h30 às 15h30" },
                  ]}
                />
                <Input name="latitude" span="Latitude *" readOnly />
                <Input name="longitude" span="Longitude *" readOnly />
                <MapContainer>
                  <Map
                    center={[latitude, longitude]}
                    zoom={13}
                    className="map-item"
                    onclick={handleMapDoubleClick}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[latitude, longitude]}>
                      <Popup>Você está aqui</Popup>
                    </Marker>
                  </Map>
                </MapContainer>
                <button type="submit">Salvar</button>
                <button
                  onClick={() => {
                    setSextaParte(false);
                    setQuintaParte(true);
                    window.scrollTo(0, 0);
                  }}
                  type="button"
                  style={{ background: "grey" }}
                >
                  Voltar
                </button>
              </Form>
            </Container>
          )}
          {finalizado && (
            <>
              <Finalizado>
                <div>
                  <img src={finalizadoImg} alt="Questionário Finalizado" />
                  <h2>Questionário Finalizado</h2>
                </div>
              </Finalizado>
            </>
          )}
        </section>
      </BackgroundQuestionario>
    </>
  );
}

export default Editar;
