import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button } from "tabler-react";

import api from "../../Services/api";

function Home() {
  const [questionarios, setQuestionarios] = useState([]);

  useEffect(() => {
    async function loadData() {
      try {
        const { data } = await api.get("questionario/all");

        setQuestionarios(data);
      } catch (error) {
        console.log(error);
        alert("Não possível carregar os dados");
      }
    }

    loadData();
  }, []);
  return (
    <div className="container" style={{ marginTop: "20px" }}>
      <Button
        onClick={() => {
          localStorage.clear();
          window.location.href = "/";
        }}
        color="info"
        className="float-right mb-5"
      >
        Sair
      </Button>
      <Table striped responsive>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Sexo</th>
            <th>Território</th>
            <th>Telefone</th>
            <th>Data Nascimento</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {questionarios.map((questionario) => (
            <tr key={questionario.id}>
              <td>{questionario.nome}</td>
              <td>{questionario.sexo}</td>
              <td>{questionario.territorioDeSaude}</td>
              <td>{questionario.telefone}</td>
              <td>{questionario.dataDeNascimento}</td>
              <td>
                <Link
                  className="btn btn-primary"
                  to={`/app/${questionario.id}/editar`}
                >
                  Editar
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

export default Home;
