import styled, { css } from 'styled-components';

export const Container = styled.div`
    background: transparent;
    width: 100%;
    color: #040317;
    & + div {
        margin-top: 1.5em;
    }
`;

export const ContainerInput = styled.div`
        background: #FFF;
        border-radius: 10px;
        border: 2px solid #FFF;
        padding: 8px;
        width: 100%;
        
        align-items: center;
        margin-top: 0.8em;
        color: #ADB4C9;

        ${(props) =>
        props.isFilled &&
        css`
            border-color: #0930EA;
            color: #0930EA;
        `}

        ${(props) =>
        props.isErrored &&
        css`
            border-color: #c53030;
        `}

        ${(props) =>
        props.isFocused &&
        css`
            color: #0930EA;
            border-color: #0930EA;
        `}

        input {
            flex: 1;
            background: transparent;
            border: 0;
            color: #040317;
            font-size: 16px;
        }

        svg {
            margin-right: 16px;
        }
`;