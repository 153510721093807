import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Editar from "./Pages/Editar";
import Questionario from "./Pages/Questionario";
import Confirmados from "./Pages/Confirmados";

import PublicRoute from "./Components/PublicRoute";
import PrivateRoute from "./Components/PrivateRoute";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Questionario} />
        <Route path="/confirmados" component={Confirmados} />
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute exact path="/app" component={Home} />
        <PrivateRoute path="/app/:id/editar" component={Editar} />
      </Switch>
    </BrowserRouter>
  );
}
