import styled, { css } from "styled-components";

export const Container = styled.div`
  background: transparent;
  width: 100%;
  color: #040317;
  & + div {
    margin-top: 1.5em;
  }
`;

export const ContainerInput = styled.div`
        background: #FFF;
        border-radius: 10px;
        border: 2px solid #FFF;
        padding: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 0.8em;
        color: #ADB4C9;

        ${(props) =>
          props.readOnly &&
          css`
            background-color: #e9ecef;
            border-color: #e9ecef;
            opacity: 1;
          `}


        ${(props) =>
          props.isFilled &&
          css`
            border-color: #0930ea;
            color: #0930ea;
          `}

        ${(props) =>
          props.isErrored &&
          css`
            border-color: #c53030;
          `}

        ${(props) =>
          props.isFocused &&
          css`
            color: #0930ea;
            border-color: #0930ea;
          `}

        input {
            flex: 1;
            background: transparent;
            border: 0;
            color: #040317;
            font-size: 16px;
        }

        svg {
            margin-right: 16px;
        }
`;
