import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from "react-select";
import "./select.css";
import { useField } from "@unform/core";
import { Container, ContainerInput } from "./styles";

export default function Select({ name, span, ...rest }) {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (selectRef.current.state.value) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: "state.value",
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option) => option.value);
        } else {
          if (!ref.state.value) {
            return "";
          }
          return ref.state.value.value;
        }
      },
      setValue(ref, value) {
        const valor = ref.props.options.find(
          (option) => option.value === value
        );
        ref.onChange(valor);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <Container>
      <span>{span}</span>
      <ContainerInput
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
      >
        <ReactSelect
          defaultValue={
            defaultValue &&
            rest.options.find((option) => option.value === defaultValue)
          }
          ref={selectRef}
          classNamePrefix="react-select"
          className="selectInput"
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          placeholder=""
          {...rest}
        />
      </ContainerInput>
      {error && (
        <span className="error" style={{ color: "red", marginTop: "1rem" }}>
          {error}
        </span>
      )}
    </Container>
  );
}
