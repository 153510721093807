import React from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import { LoginPage as TablerLoginPage } from "tabler-react";

import api from "../../Services/api";
import { useUserDispatch } from "../../context/UserContext";

export default function Login() {
  const userDispatch = useUserDispatch();
  const history = useHistory();

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validate={(values) => {
        // same as above, but feel free to move this into a class method now.
        let errors = {};
        if (!values.email) {
          errors.email = "Campo Obrigatório";
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
          errors.email = "Email Inválido";
        }
        return errors;
      }}
      onSubmit={async (
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        try {
          const { data: response } = await api.post("login", values);

          localStorage.setItem("access_token", response.data.token);
          userDispatch({ type: "LOGIN_SUCCESS" });
          history.push("/app");
        } catch (error) {
          console.log(error);
          alert("Login Inválido.");
        }
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <TablerLoginPage
          onSubmit={handleSubmit}
          onChange={handleChange}
          onBlur={handleBlur}
          values={values}
          errors={errors}
          touched={touched}
          strings={{
            title: "Realize seu Login",
            emailLabel: "E-mail",
            emailPlaceholder: "Informe seu email",
            passwordLabel: "Senha",
            passwordPlaceholder: "Informe sua senha",
          }}
        />
      )}
    />
  );
}
