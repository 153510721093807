import styled from "styled-components";

export const Container = styled.div`
  display: block;
  height: 100vh;

  .map-item {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #aad3df;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .leaflet-marker-shadow {
    width: 0 !important;
  }
`;
