import React, { useEffect, useCallback, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";

import { Container, ContainerInput } from "./styles";
import { useField } from "@unform/core";

export default function InputMask({ name, span, ...rest }) {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (inputRef.current?.value) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <span>{span}</span>
      <ContainerInput
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        readOnly={rest.readOnly}
      >
        <ReactInputMask
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          defaultValue={defaultValue}
          ref={inputRef}
          {...rest}
        />
      </ContainerInput>
      {error && (
        <span className="error" style={{ color: "red", marginTop: "1rem" }}>
          {error}
        </span>
      )}
    </Container>
  );
}
