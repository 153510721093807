import React from "react";

import GlobalStyle from "./Styles/global";
import Routes from "./routes";
import { UserProvider } from "./context/UserContext";

function App() {
  return (
    <>
      <UserProvider>
        <GlobalStyle />
        <Routes />
      </UserProvider>
    </>
  );
}

export default App;
