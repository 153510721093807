import styled from "styled-components";
import { shade } from "polished";

import medicaInicioQuestionario from "../../Assets/medicaInicioQuestionario.svg";
import bolha from "../../Assets/bolha.svg";

export const QuestionarioContainer = styled.div`
  background: #0930ea url(${medicaInicioQuestionario}) no-repeat 50% 25%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  section {
    width: 100%;
    height: 56%;
    background: #f0f0f5;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    h2 {
      color: #1a1053;
      text-transform: uppercase;
    }

    button,
    a {
      font-size: 16px;
      width: 35%;
      height: 3em;
      background: #0930ea;
      color: #f0f0f5;
      border-radius: 3px;
      border: 0;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, "#0930EA")};
      }
    }

    div {
      img + img {
        margin-left: 2em;
      }
      img {
        width: auto;
        height: 45px;
      }
    }
  }
`;

export const BackgroundQuestionario = styled.div`
  background: #f0f0f5 url(${bolha}) no-repeat right bottom;
  height: auto;

  section {
    max-width: 80%;
    margin: 0 auto;
  }
`;

export const Container = styled.div`
  animation: fadeIn 0.3s ease-in;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 175px;
    margin-top: 2.5em;
  }

  h3 {
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    color: #1a1053;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
  }

  button {
    margin-top: 2em;
    margin-bottom: 2em;
    width: 100%;
    height: 3em;
    background: #0930ea;
    color: #f0f0f5;
    border-radius: 3px;
    border: 0;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, "#0930EA")};
    }
  }
`;

export const Finalizado = styled.section`
  height: 100vh;
  background: #f0f0f5;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: transparent;

    img {
      width: auto;
      height: 80px;
    }
    h2 {
      margin-top: 1em;
    }

    button {
      margin-top: 2rem;
      font-size: 16px;
      width: 100%;
      height: 3em;
      background: #0930ea;
      color: #f0f0f5;
      border-radius: 3px;
      border: 0;
      transition: background-color 0.2s;

      &:hover {
        background: ${shade(0.2, "#0930EA")};
      }
    }
  }
`;

export const MapContainer = styled.div`
  display: block;
  height: 80vh;

  .map-item {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #aad3df;
    align-items: center;
    align-content: center;
    text-align: center;
  }

  .leaflet-marker-shadow {
    width: 0 !important;
  }
`;
