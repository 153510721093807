import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    body, input, button{
        font-family: 'Roboto', sans-serif;
    }
    
    button{
        cursor: pointer;
    }

    @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate3D(50px, 0, 0);
    }
    to{
        opacity: 1;
        transform: translate3D(0px, 0, 0);
    }
}
`;
