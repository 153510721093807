import React, { useEffect, useState } from "react";

import { Map, Marker, Popup, TileLayer } from "react-leaflet";
import { Container } from "./styles";

import api from "../../Services/api";

function Confirmados() {
  const [confirmados, setConfirmados] = useState([]);

  async function loadData() {
    try {
      const { data } = await api.get("/questionario/confirmados");
      setConfirmados(data);
    } catch (error) {
      console.log(error);
      alert("Erro na busca dos dados.");
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container>
      <Map center={[-10.184079, -48.3343345]} zoom={13} className="map-item">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {confirmados.length &&
          confirmados.map((confirmado) => (
            <Marker
              position={[confirmado.latitude, confirmado.longitude]}
              key={confirmado.id}
            >
              <Popup>Sexo: {confirmado.sexo}</Popup>
            </Marker>
          ))}
      </Map>
    </Container>
  );
}

export default Confirmados;
