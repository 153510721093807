import React from "react";
import { Route, Redirect } from "react-router-dom";

import { useUserState } from "../../context/UserContext";

export default function PublicRoute({ component, ...rest }) {
  const { isAuthenticated } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Redirect
            to={{
              pathname: "/app",
            }}
          />
        ) : (
          React.createElement(component, props)
        )
      }
    />
  );
}
